
import {defineComponent, computed, nextTick} from 'vue';
import {
    IonItem,
    IonIcon,
    loadingController,
    IonLabel
} from '@ionic/vue';
import {useStore} from "vuex";
import {closeOutline, musicalNote} from 'ionicons/icons';
import {audioFileChanged} from "@/utils/AudioUtil";

export default defineComponent({
    components: {
        IonItem,
        IonIcon,
        IonLabel
    },
    props: {
        data: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        buttonLabel:{
            type: String,
            required:false
        }
    },
    emits: ['update:data', 'update:name'],
    setup(props, ctx) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);

        const removeAudio = () => {
            ctx.emit('update:name', '');
            ctx.emit('update:data', '')
        }

        const addAudio = async (event: any) => {
            const loading = await loadingController.create({
                message: language.value.savingProgress,
            });
            await loading.present();
            await nextTick();
            const { filename, data } = await audioFileChanged(event);

            ctx.emit('update:name', filename);
            ctx.emit('update:data', data)
            await loading.dismiss();
        };

        return {
            removeAudio,
            addAudio,
            closeOutline,
            musicalNote
        }
    }
})
