import {presentNativeAlert} from "@/services/Notify";
import {store} from "@/store/Store";
import {alertController} from "@ionic/vue";

async function setFile(file: File): Promise<any> {
    const reader = new FileReader();
    return await new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve({filename: file.name, data: reader.result as string});
        };
        reader.onerror = () => {
            reject({filename: '', data: ''});
        }
        reader.readAsDataURL(file);
    });
}

async function shouldContinueAudioUpload(file: File, audioErrors: string): Promise<any> {
    return await new Promise((resolve) => {
        const language = store.getters['app/language'];
        presentNativeAlert({
            cssClass: ['custom-alert','custom-audio-alert'],
            header: '',
            message:
                `${language.validation.audioProperties}
                <ul>${audioErrors}</ul>`,
            buttons: [{
                text: language.continueAnyway,
                role: 'cancel',
                handler: async () => {
                    alertController.dismiss();
                    resolve(await setFile(file));
                }}, {
                text: language.selectNewFile,
                handler: () => {
                    alertController.dismiss();
                    resolve({ filename: '', data: '' });
                }},
            ]
        });
    });
}

export const verifyAudioProperties = async (file: File): Promise<string> => {
    const audioDataReader = new FileReader();
    const language = store.getters['app/language'];
    return await new Promise((resolve) => {
        try {
            audioDataReader.onloadend = async () => {
                (window as any).AudioContext = (window as any).AudioContext || (window as any).webkitAudioContext;
                const context = new AudioContext();
                const buffer = await context.decodeAudioData(audioDataReader.result as ArrayBuffer);
                const bitrate = .008 * file.size / buffer.duration;
                let errorMsg = '';
                
                if (buffer.numberOfChannels !== 1) {
                    errorMsg += `<li>${language.validation.audioPropertyMono}</li>`;
                } 
                if (bitrate < 30 || bitrate > 34) {
                    errorMsg += `<li>${language.validation.audioPropertyBitrate}</li>`;
                }
                if (file.name.split('.')[1] !== "mp3") {
                    errorMsg += `<li>${language.validation.audioPropertyFileType}</li>`;
                }

                resolve(errorMsg);
            };
            audioDataReader.readAsArrayBuffer(file);
        } catch {
            resolve('');
        }
    });
}

export const audioFileChanged = async (event: any): Promise<any> => {
    if (event.target.files.length > 0) {
        const file = event.target.files[0];
        const audioHasErrors = await verifyAudioProperties(file);
        if (audioHasErrors) {
            return await shouldContinueAudioUpload(file, audioHasErrors);
        } else {
            return await setFile(file);
        }
    }
    return { filename: '', data: '' };
}