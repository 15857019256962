import {DELETE, GET, POST, PUT} from './HTTP';
import {JourneyTemplate} from "@/models/JourneyTemplateModel";

export function getTemplate(templateId: string): Promise<JourneyTemplate>{
    return GET('/templates/' + templateId);
}

export function createTemplate(data: any): Promise<any> {
    return POST('/templates', data);
}

export function updateTemplate(data: any): Promise<any> {
    return PUT(`/templates/${data.id}`, data);
}

export function getTemplates(appInstanceId: string): Promise<JourneyTemplate[]>{
    return GET(`/templates?appInstanceId=${appInstanceId}`);
}

export function getCompleteTemplates(appInstanceId: string): Promise<JourneyTemplate[]>{
    return GET(`/templates?appInstanceId=${appInstanceId}&status=complete`);
}

export function deleteTemplate(templateId: string): Promise<any> {
    return DELETE('/templates/'+ templateId);
}

export function duplicateTemplate(data: any): Promise<any> {
    return POST(`/templates/${data.id}`, data);
}