<template>
    <div ref="editor" @click="clicked($event)"/>
</template>

<script lang="ts">
import Editor from '@toast-ui/editor';
import {onMounted, ref} from "vue";
import {defineComponent} from "@vue/runtime-core";
import {presentValidationAlert} from "@/services/Notify";
import {useStore} from "vuex";

export default defineComponent({
    name: 'MarkdownWYSIWYG',
    props: {
        modelValue: String,
        placeholder: String
    },
    setup(props: any, { emit }) {
        const editor = ref();
        const store = useStore();
        
        onMounted(() => {
            editor.value.style.width = '100%';
            editor.value.style.cursor = 'text';
            editor.value.style.padding = '20px 0 15px 0';
            const e = new Editor({
                el: editor.value,
                height: '100%',
                initialEditType: 'wysiwyg',
                initialValue: props.modelValue ?? "",
                placeholder: props.placeholder,
                previewStyle: 'vertical',
                previewHighlight: false,
                hideModeSwitch: true,
                linkAttributes: {
                    target: '_blank'
                },
                toolbarItems: [
                    ['heading', 'bold', 'italic'],
                    ['ul'],
                    ['image'],
                    ['link']
                ],
                events: {
                    change: () => {
                        emit('update:modelValue', e.getMarkdown());
                    }
                },
                hooks: {
                    addImageBlobHook: (blob, callback) => {
                        if (blob.size > 512000) { //512kb
                            presentValidationAlert(store.getters['app/language'].validation.markdownImageSize);
                            return;
                        }
                        
                        if (blob.type === "image/svg+xml") {
                            presentValidationAlert(store.getters['app/language'].validation.svgExtension);
                            return;
                        }
                        
                        const reader = new FileReader();
                        reader.onload = ({ target }) => callback(target!.result as string);
                        reader.readAsDataURL(blob);
                    }
                }
            });
        });
        
        const clicked = (event: any) => {
            const link = event.target?.getAttribute('href');
            if (link) {
                window.open(link, event.target?.getAttribute('target') ?? '_blank');
            }
        }

        return {
            editor,
            clicked
        }
    }
});
</script>

<style scoped>
</style>