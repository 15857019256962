<template>
    
    <ion-item v-if="!data" lines="none" mode="md">
        
        <div class="button-with-label">
            <button type="button" class="custom-button button-dark file-button" >
                <ion-icon class="button-icon" :icon="musicalNote"></ion-icon>
                <span>{{ label }}</span>
                <input
                    type="file"
                    ref="scriptureAudio"
                    @change="addAudio"
                    accept=".mp3"/>
            </button>
            <ion-label class="center-button-label" v-if="buttonLabel">
               {{buttonLabel}}
            </ion-label>
        </div>
    </ion-item>
    <ion-item v-else lines="full" mode="md">
        <div  class="file-label">
            <a :href="data" :download="name">{{ name }}</a>
            <div class="icon-container" @click="removeAudio"><ion-icon :icon="closeOutline"></ion-icon></div>
        </div>
    </ion-item>
</template>

<script lang="ts">
import {defineComponent, computed, nextTick} from 'vue';
import {
    IonItem,
    IonIcon,
    loadingController,
    IonLabel
} from '@ionic/vue';
import {useStore} from "vuex";
import {closeOutline, musicalNote} from 'ionicons/icons';
import {audioFileChanged} from "@/utils/AudioUtil";

export default defineComponent({
    components: {
        IonItem,
        IonIcon,
        IonLabel
    },
    props: {
        data: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        buttonLabel:{
            type: String,
            required:false
        }
    },
    emits: ['update:data', 'update:name'],
    setup(props, ctx) {
        const store = useStore();
        const language = computed(() => store.getters['app/language']);

        const removeAudio = () => {
            ctx.emit('update:name', '');
            ctx.emit('update:data', '')
        }

        const addAudio = async (event: any) => {
            const loading = await loadingController.create({
                message: language.value.savingProgress,
            });
            await loading.present();
            await nextTick();
            const { filename, data } = await audioFileChanged(event);

            ctx.emit('update:name', filename);
            ctx.emit('update:data', data)
            await loading.dismiss();
        };

        return {
            removeAudio,
            addAudio,
            closeOutline,
            musicalNote
        }
    }
})
</script>


<style scoped>
  
.custom-button {
    margin-bottom: 20px;
}

</style>